import styles from '../styles/Home.module.css';
import { NextSeo } from 'next-seo';

const Home = () => {
  return (
    <>
      <NextSeo
        title="Reddy Meet: 404 Not found"
        description="404. Not found"
        openGraph={{
          url: 'https://conf.reddy.team',
          site_name: 'Reddy Meet',
          type: 'website',
          locale: "en",
          description: "404. Not found",
          images: []
        }}
      />
      <main className={styles.main} data-lk-theme="default">
        <div className="header">
          <img src="/not_found.png" />
          <h1 className="err">
            404. Page not found
          </h1>
        </div>
      </main>
    </>
  );
};

export default Home;
